




import { Component, Vue } from "vue-property-decorator";

import MenuControlloAlloggi from "@/components/menus/controllo-alloggi/MenuControlloAlloggi.vue";

@Component({
  components: {
    MenuControlloAlloggi,
  },
})
export default class ControlloAlloggiMenuView extends Vue {

}
