




import { Component, Vue } from "vue-property-decorator";
import Controlli from "@/components/pages/controlli/Controlli.vue";

@Component({
  components: {
    Controlli,
  },
})
export default class ControlloAlloggiControlliView extends Vue {

}
